exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-game-components-actions-end-index-js": () => import("./../../../src/pages/game/components/actions-end/index.js" /* webpackChunkName: "component---src-pages-game-components-actions-end-index-js" */),
  "component---src-pages-game-components-actions-playing-index-js": () => import("./../../../src/pages/game/components/actions-playing/index.js" /* webpackChunkName: "component---src-pages-game-components-actions-playing-index-js" */),
  "component---src-pages-game-components-modal-end-index-js": () => import("./../../../src/pages/game/components/modal-end/index.js" /* webpackChunkName: "component---src-pages-game-components-modal-end-index-js" */),
  "component---src-pages-game-components-modal-intro-index-js": () => import("./../../../src/pages/game/components/modal-intro/index.js" /* webpackChunkName: "component---src-pages-game-components-modal-intro-index-js" */),
  "component---src-pages-game-components-modal-stop-index-js": () => import("./../../../src/pages/game/components/modal-stop/index.js" /* webpackChunkName: "component---src-pages-game-components-modal-stop-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

